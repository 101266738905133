<template>
  <div>
    <DeliveryStatFilter :FACTORY_OPTS="branch_list" :CORRESPONDENT_OPTS="correspondent_list" :FREIGHT_OPTS="freight_list" @query="onQueryDelivery" />
    <CRow>
      <CCol md="12" lg="6">
        <DeliveryValidityCount :stats="stats_delivery_counts" />
      </CCol>
      <CCol md="12" lg="6">
        <DeliveryReceivedAmount :stats="stats_delivery_amount" />
      </CCol>
      <CCol md="12" lg="6">
        <DeliveryBadCorrespondent :stats="stats_delivery_correspondent" />
      </CCol>
      <CCol md="12" lg="6">
        <DeliveryFreightAmount :stats="stats_delivery_freight" :titleSuffix="freight_cate" @change-cate="onChangeCate" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'

import DeliveryStatFilter from './cards/StatFilter.vue'
import DeliveryValidityCount from './cards/ValidityCount.vue'
import DeliveryFreightAmount from './cards/FreightAmount.vue'
import DeliveryReceivedAmount from './cards/ReceivedAmount.vue'
import DeliveryBadCorrespondent from './cards/BadCorrespondent.vue'
import { mapState } from 'vuex'

export default {
  name: 'DeliveryStatisticsDaily',

  components: {
    DeliveryStatFilter,
    DeliveryValidityCount,
    DeliveryReceivedAmount,
    DeliveryBadCorrespondent,
    DeliveryFreightAmount
  },
  data() {
    return {
      filters: null,
      branch_list: [],

      // Delivery management service
      freight_cate: null,
      freight_list: [],
      correspondent_list: [],

      stats_delivery_counts: null,
      stats_delivery_amount: null,
      stats_delivery_freight: null,
      stats_delivery_correspondent: null
    }
  },
  mounted() {
    this.initFetch();
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    regions() {
      let rtn = [];
      for (const branch of this.branch_list) {
        let rtn_region = rtn.find(el => el.name === branch.region_name);
        if (!rtn_region) {
          rtn.push({
            name: branch.region_name,
            id: branch.region,
            options: [
              {name: branch.name, id: branch.id}
            ]
          })
        } else {
          rtn_region.options.push({name: branch.name, id: branch.id});
        }
      }
      rtn = rtn.sort((a,b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return rtn;
    }
  },
  methods: {
    initFetch() {
      this.getBranchList();
      this.getFreightList();
      this.getCorrespondentList();
    },
    getBranchList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 500, // FIXME. 난중에 하자...
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/branches/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        })
    },
    getFreightList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 1000,
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/freights/${query_string}`)
        .then(result => {
          this.freight_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
          this.FREIGHT_OPTS = this.freight_list.map(el => {
            return { value: el.id, label: el.name };
          });
        })
        .catch(e => {
          console.error(e);
        })
    },
    getCorrespondentList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 1000,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/correspondents/${query_string}`)
        .then(result => {
          this.correspondent_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(e => {
          console.error(e);
        })
    },
    onQueryDelivery(stat_filter) {
      this.filters = stat_filter;
      this.statValidityCounts();
      this.statReceivedAmount();
      this.statBadCorrespondent();
      if (this.freight_cate)
        this.statReceivedFreight();
    },
    onChangeCate(cate) {
      if (this.freight_cate !== cate) {
        this.freight_cate = cate;
        this.statReceivedFreight();
        return;
      }
    },

    statValidityCounts() {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        params.correspondent = this.filters.correspondent;
        params.origin = this.filters.origin;
        params.freight = this.filters.freight;
        if (this.filters.date) {
          this.filters.date.setHours(0);
          this.filters.date.setMinutes(0);
          this.filters.date.setSeconds(0);
          this.filters.date.setMilliseconds(0);
          params.sts = this.filters.date.getTime();
          params.ets = params.sts + (1000 * 60 * 60 * 24);
        }
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/statistics/validity-count/${query_string}`)
        .then(result => {
          this.stats_delivery_counts = result.data;
        })
        .catch(error => {
          console.error(error);
        })
    },
    statReceivedAmount() {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        params.correspondent = this.filters.correspondent;
        params.origin = this.filters.origin;
        params.freight = this.filters.freight;
        if (this.filters.date) {
          this.filters.date.setHours(0);
          this.filters.date.setMinutes(0);
          this.filters.date.setSeconds(0);
          this.filters.date.setMilliseconds(0);
          params.sts = this.filters.date.getTime();
          params.ets = params.sts + (1000 * 60 * 60 * 24);
        }
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/statistics/received-amount/${query_string}`)
        .then(result => {
          this.stats_delivery_amount = result.data;
        })
        .catch(error => {
          console.error(error);
        })
    },
    statReceivedFreight() {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        params.correspondent = this.filters.correspondent;
        params.origin = this.filters.origin;
        params.freight = this.filters.freight;
        if (this.filters.date) {
          this.filters.date.setHours(0);
          this.filters.date.setMinutes(0);
          this.filters.date.setSeconds(0);
          this.filters.date.setMilliseconds(0);
          params.sts = this.filters.date.getTime();
          params.ets = params.sts + (1000 * 60 * 60 * 24);
        }
        params.cate1 = this.freight_cate;
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/statistics/received-freight/${query_string}`)
        .then(result => {
          this.stats_delivery_freight = result.data;
        })
        .catch(error => {
          console.error(error);
        })
    },
    statBadCorrespondent() {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        params.correspondent = this.filters.correspondent;
        params.origin = this.filters.origin;
        params.freight = this.filters.freight;
        if (this.filters.date) {
          this.filters.date.setHours(0);
          this.filters.date.setMinutes(0);
          this.filters.date.setSeconds(0);
          this.filters.date.setMilliseconds(0);
          params.sts = this.filters.date.getTime();
          params.ets = params.sts + (1000 * 60 * 60 * 24);
        }
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/statistics/bad-correspondent/${query_string}`)
        .then(result => {
          this.stats_delivery_correspondent = result.data;
        })
        .catch(error => {
          console.error(error);
        })
    }
  }
}
</script>
