<template>
  <CardContainer :title="title" v-if="stats">
    <template slot="view_menu" v-if="false">
      <CButton color="secondary" size="sm" class="d-inline-block float-right ml-2">
        <svgicon name="settings" width="16px" height="16px" />
      </CButton>
    </template>
    <template slot="chart">
      <CChartDoughnut
        :datasets="chartData"
        :labels="chartLabel"
        :options="chartOption"
      />
    </template>
  </CardContainer>
</template>

<script>
import CardContainer from '@/containers/StatisticsCard.vue'
import { CChartDoughnut } from '@coreui/vue-chartjs'

export default {
  name: 'ReceivedAmount',
  components: {
    CardContainer,
    CChartDoughnut
  },
  props: {
    stats: {type: Object}
  },
  data() {
    return {
      title: '입고량',
      colors: ['#6ebe9f', '#c73558'],
      labels: []
    }
  },
  computed: {
    // *** datasets configurations ***
    // Name	                    Type         Scriptable      Indexable      Default
    // -----------------------------------------------------------------------------------------
    // backgroundColor          Color        Yes             Yes            'rgba(0, 0, 0, 0.1)'
    // borderAlign              string       Yes             Yes            'center'
    // borderColor              Color        Yes             Yes            '#fff'
    // borderWidth              number       Yes             Yes            2
    // data                     number[]     -               -              required
    // hoverBackgroundColor     Color        Yes             Yes            undefined
    // hoverBorderColor         Color        Yes             Yes            undefined
    // hoverBorderWidth         number       Yes             Yes            undefined
    // weight                   number       -               -              1
    chartData() {
      let valid_amount = this.stats.valid_amount;
      let invalid_amount = this.stats.invalid_amount;
      return [
        {
          backgroundColor: this.colors,
          data: [valid_amount, invalid_amount]
        }
      ];
    },
    chartLabel() {
      if (this.stats.total_amount > 0) {
        let valid_amount = this.stats.valid_amount;
        let invalid_amount = this.stats.invalid_amount;
        return [`정상입고량 ${valid_amount} ㎥`, `부족입고량 ${invalid_amount} ㎥`];
      }
      return ['입고량 없음']
    },
    chartOption() {
      return {
        legend: {
          position: 'right',
          align: 'center',
          labels: {
            fontSize: 12
          }
        }
      };
    }
  }
}
</script>
